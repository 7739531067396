<template>
  <div>
    <FullScreenDialog
      v-model="localOpenDialog"
      persistent
      :title="title"
      :can-close="false"
    >
    <template v-slot:content>
      <ItemForm
        :barcode-scanned="barcodeScanned"
        :editing="false"
        :supplier-selected-outside = "supplierSelected"
        class="py-2"
        :loading="loadingForm"
      ></ItemForm>
    </template>
      <template v-slot:footer-actions>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="close">Annulla</v-btn>
        <v-btn text color="default" @click="save" :disabled="!formValid" :loading="loading"
          >Salva</v-btn
        >
      </template>
    </FullScreenDialog>

    <StandardDialog
      dialog-width="460"
      dialog-height="calc(360-10px)"
      persistent
      v-model="showErrorDialog"
      :title="titleError"
    >
      <v-alert class="ma-2" dense outlined type="error">
        {{ errorMessage }}
      </v-alert>

      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="showErrorDialog = false"
          >Chiudi</v-btn
        >
      </template>
    </StandardDialog>
  </div>
</template>

<script>
import ItemForm from "@/components/areas/warehouse/items/ItemForm.vue";
import itemsService from "@/services/warehouse/items.service.js";
import StandardDialog from "@/components/common/StandardDialog";
import FullScreenDialog from "@/components/common/FullScreenDialog";
import itemVocabulary from "@/vocabulary/warehouse/item.vocabulary.js";

export default {
  components: {
    StandardDialog,
    ItemForm,
    FullScreenDialog,
  },
  data: function () {
    return {
      localOpenDialog: false,
      loadingForm: false,
      showErrorDialog: false,
      errorMessage: "",
      titleError: "",
      loading: false,
    };
  },
  props: {
    fromUploader: {
      type: Boolean,
      default: false,
    },
    openDialog: {
      type: Boolean,
      default: false,
    },
    barcodeScanned: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Creazione Prodotto",
    },
    supplierSelected: {
      type: Number,
      default: undefined,
    },
    rootFather: {
      type: String,
      default: "",
    }
  },
  mounted() {
    this.$store.dispatch("warehouse/items/resetFields");

    this.localOpenDialog = this.openDialog;
  },
  computed:{
    formValid() {
      return this.$store.state.warehouse.items.data.valid;
    },
  },
  methods: {
    save() {
      if (!this.$store.state.warehouse.items.data.item.supplierId) {
        this.errorMessage = itemVocabulary.Supplier_NotSelected.desc;
        this.showErrorDialog = true;
      }
      else if(!this.$store.state.warehouse.items.data.item.description){
        this.errorMessage = itemVocabulary.Desc_NotPresent.desc;
        this.showErrorDialog = true;
      }
      else if(!this.$store.state.warehouse.items.data.item.code){
        this.errorMessage = itemVocabulary.Code_NotPresent.desc;
        this.showErrorDialog = true;  
      } 
      else {
        let itemToCreate = this.$store.state.warehouse.items.data.item;
        this.loading = true;
        itemsService
        .create({ item: itemToCreate, barcode: itemToCreate.barcode })
        .then((itemCreated) => {
          this.loading = false;
          if (!this.fromUploader) {
            if(this.rootFather == 'orderUpdateDialog'){
              this.$emit("creation-item", itemCreated);
              this.localOpenDialog = false;
            } else {
              this.$emit('close',true)
              this.$router.push({ name: "ItemList" }).catch(() => {});
            }
          } else {
            this.localOpenDialog = false;
            this.$emit("creation-item", itemCreated);
          }

        })
        .catch((err) => {
          this.loading = false;
          if(err == 'Code Already Present')
            err = 'Codice a Barre Già Inserito'
          this.errorMessage = err;
          this.showErrorDialog = true;
        });
      }
    },

    close() {
      this.$emit("close",false);
    },
  },
  watch:{
    openDialog(newVal){
      this.localOpenDialog = newVal
    }
  }
};
</script>

<style>
</style>