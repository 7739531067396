<template>
  <div class="mb-3 mr-3">
    <v-subheader class="font-italic font-weight-light"> </v-subheader>
    <v-row no-gutters>
      <div class="text-h7 ml-4">Totale ante sconto:</div>
      <h3 class="ml-3">€ {{ amountOrder }}</h3>
      <div class="text-h7 ml-4">Sconti Applicati:</div>
      <h3 class="ml-3">€ - {{ discountForElements }}</h3>
      <div class="text-h7 ml-4">Sub totale:</div>
      <h3 class="ml-3">€ {{ subtotalOrder }}</h3>

      <v-divider vertical class="ml-5"></v-divider>

      <div class="text-h7 ml-4">Extra Sconto</div>
      <h3 class="mb-3 ml-1 mr-1 d-flex aling-center justify-center">
        <v-text-field
          type="number"
          min="0"
          max="100"
          dense
          :rules="[]"
          filled
          rounded
          :disabled="!!statusOrder && statusOrder != 'created'"
          hide-details="auto"
          not-specified-text="0"
          v-model="extraDiscount"
          @input="updateDiscount()"
        >
        </v-text-field>
        %
      </h3>

      <div class="text-h7 ml-4">Totale Generato (con extra sconto):</div>

      <h2>€ {{ totalWithDiscount }}</h2>
    </v-row>
  </div>
</template>

<script>
import BorderedDiv from "@/components/common/BorderedDivComponent.vue";
export default {
  name: "OrderSummary",
  components: {
    BorderedDiv,
  },
  data() {
    return {
      statusOrder: !!this.selectedItems.statusOrder,
      localItems: this.selectedItems,
      extraDiscount: !!this.selectedItems.extraDiscount
        ? Number(this.selectedItems.extraDiscount).toFixed(0)
        : Number(0).toFixed(0),
    };
  },
  props: {
    selectedItems: {
      type: Array,
      deafult: () => [],
    },
    variant: {
      type: String,
      default: "new",
      validator: function (value) {
        if (!["new", "edit", "detail"].includes(value)) {
          return "invalid variant";
        } else {
          return true;
        }
      },
    },
  },
  methods: {
    updateDiscount() {
      setTimeout(() => {
        if(this.extraDiscount > 100) {
          this.extraDiscount = 100
        } else if(this.extraDiscount < 0) {
          this.extraDiscount = 0
        }

        this.$store.dispatch("warehouse/orders/updateField", {
          field: 'discount',
          value: this.extraDiscount,
        });
      }, 50);
    },
    updateField(nameField, value) {
      this.$store.dispatch("warehouse/orders/updateField", {
        field: nameField,
        value: value,
      });
    },
    quantity(el) {
      return !!el["qtyTidying"] ? el["qtyTidying"] : el["qtyToOrder"];
    },
  },
  computed: {
    amountOrder() {
      if (this.localItems.length > 0) {
        let elements = this.localItems;
        let totalAmountForElements = 0;
        elements.map((el) => {
          totalAmountForElements +=
            Number(el.pricePurchase) * Number(this.quantity(el));
        });
        return Number(totalAmountForElements).toFixed(2);
      }
      return Number(0).toFixed(2);
    },
    discountForElements() {
      if (this.localItems.length > 0) {
        let elements = this.localItems;
        let totalDiscountForElements = 0;
        elements.map((el) => {
          totalDiscountForElements +=
            Number(el.pricePurchase) * Number(this.quantity(el)) -
            (Number(el.discount)
              ? ((Number(el.pricePurchase) * Number(el.discount)) / 100) *
                Number(this.quantity(el))
              : 0);
        });
        return Number(
          Number(this.amountOrder) - Number(totalDiscountForElements)
        ).toFixed(2);
      }
      return (0).toFixed(2);
    },
    subtotalOrder() {
      if (this.localItems.length > 0) {
        let elements = this.localItems;
        let totalDiscountForElements = 0;
        elements.map((el) => {
          totalDiscountForElements +=
            Number(el.pricePurchase) * Number(this.quantity(el)) -
            (el.discount
              ? ((Number(el.pricePurchase) * Number(el.discount)) / 100) *
                Number(this.quantity(el))
              : 0);
        });
        return Number(totalDiscountForElements).toFixed(2);
      }
      return Number(0).toFixed(2);
    },

    totalWithDiscount() {
      if (
        this.localItems.length > 0 &&
        (!!Number(this.extraDiscount) || Number(this.extraDiscount) > 0)
      ) {
        let totalDiscount =
          (this.subtotalOrder * Number(this.extraDiscount)) / 100;
        this.updateField(
          "amount",
          Number(this.subtotalOrder - Number(totalDiscount))
        );

        return Number(this.subtotalOrder - Number(totalDiscount)).toFixed(2);
      } else {
        this.updateField("amount", this.subtotalOrder);

        return this.subtotalOrder;
      }
    },
  },
  watch: {
    selectedItems(val) {
      this.localItems = val;
      this.extraDiscount = val.extraDiscount;
      this.statusOrder = val.statusOrder;
    },
  },
};
</script>

<style>
</style>