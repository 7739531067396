import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js';
import DateHandler from "@/services/common/dateHandler";

class movementManagment {
  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/movements/list", {
          page: page,
          rowPerPage: rowPerPage,
          filters: filters,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        });
    });
  }
  createMovement(movement) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/movements/create", movement).then((response) => {
        if (response.success) {
          resolve(response.results);
        } else {
          reject(response.results.error);
        }
      });
    });
  }

  updateMovement(movement) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/movements/update", movement).then((response) => {
        if (response.success) {
          resolve(response.results);
        } else {
          reject("error during the call");
        }
      });
    });
  }

  get(movementId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/movements/get", { id: movementId })
        .then((response) => {
          resolve(response.results);
        });
    });
  }

  addOrder(orderId, movementId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/movements/orders/" + movementId + "/add", {
          orderId: orderId,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            console.log(response);
            reject("error during the call");
          }
        });
    });
  }

  getMovementsByOrderId(orderId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/movements/getMovementByOrderId/" + orderId)
        .then((response) => {
          resolve(response.results);
        });
    });
  }
  getMovementByImportId(importId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/movements/getMovementByImportId/" + importId)
        .then((response) => {
          resolve(response.results[0]);
        });
    });
  }

  getMovementItems(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/movements/items/list", {
          page: page,
          rowPerPage: rowPerPage,
          filters: filters,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            console.log(response);
            reject("error during the call");
          }
        });
    });
  }

  getMovementItemsByMovementId(page, rowPerPage, filters, movementId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/movements/items/" + movementId + "/list", {
          page: page,
          rowPerPage: rowPerPage,
          filters: filters,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            console.log(response);
            reject("error during the call");
          }
        });
    });
  }



  unloadFromScanner(itemsToUnload, userId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/movements/unload", {
          userId: userId,
          items: itemsToUnload,
        })
        .then((response) => {
          if (response.success) {
            resolve();
          } else reject();
        });
    });
  }



  uploadFromScanner(itemsToUpload, userId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/movements/upload", {
          userId: userId,
          items: itemsToUpload,
          movementDesc: "Caricamento del magazzino da scanner",
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else reject();
        });
    });
  }

  uploadFromOrder(itemsToUpload, userId, orderId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/movements/upload", {
          userId: userId,
          items: itemsToUpload,
          movementDesc: "Caricamento del magazzino da ordine",
          orderId: orderId,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else reject();
        });
    });
  }



  _fields() {
    return Promise.resolve([
      {
        text: "Carico/Scarico",
        value: "type",
        type: "custom",
      },
      {
        text: "Codice Interno Movimento",
        value: "movement.code",
      },
      {
        text: "Quantità Caricata/Scaricata",
        value: "qty",
        type: "custom",
      },
      {
        text: "Codice Interno",
        value: "item.internalCode",
      },
      {
        text: "Barcode",
        value: "code",
      },
      {
        text: "Descrizione",
        value: "item.description",
      },

      {
        text: "Ora Creazione",
        value: "createdAtTime",
        type: "custom",
      },
      {
        text: "Data Movimento",
        value: "createdAt",
        type: "date",
      },
    ]);
  }
  _fields_items_small() {
    return Promise.resolve([
      {
        text: "Carico/Scarico",
        value: "type",
        type: "custom",
      },
      {
        text: "Codice Movimento",
        value: "movementCode",
      },
      {
        text: "Codice Interno",
        value: "item.internalCode",
      },
      {
        text: "Barcode",
        value: "code",
      },
      {
        text: "Descrizione",
        value: "item.description",
      },
      {
        text: "Quantità Caricata/Scaricata",
        value: "qty",
        type: "custom",
      },
      {
        text: "Ora Creazione",
        value: "createdAtTime",
        type: "custom",
      },
      {
        text: "Data Movimento",
        value: "createdAt",
        type: "date",
      },
    ]);
  }
  _fields_small() {
    return Promise.resolve([
      {
        text: "Carico/Scarico",
        value: "type",
        type: "custom",
      },
      {
        text: "Codice Interno Movimento",
        value: "movement.code",
      },
      {
        text: "Barcode",
        value: "code",
      },
      {
        text: "Quantità Caricata/Scaricata",
        value: "qty",
        type: "custom",
      },

      {
        text: "Ora Movimento",
        value: "createdAtTime",
        type: "custom",
      },
      {
        text: "Data Movimento",
        value: "createdAt",
        type: "date",
      },
    ]);
  }

  _fieldsToExport(type = 'upload') {
    return {
      'Descrizione': 'description',
      'Nome File': 'fileName',
      'Estensione': 'extension',
      'Data Creazione': {
        field: 'createdAt',
        callback: (value) => {
          return DateHandler._buildDateToExport(`${value}`, true);
        }
      },
      'Prodotti totali da importare': 'countItems',
      'Prodotti importati': 'countItemsImported',
      'Prodotti in errore': 'countItemsInError',
      'Completata': {
        field: 'allRowsImported',
        callback: (value) => {
          return value ? 'Si' : 'No';
        }
      },
    }
  }
}

export default new movementManagment();
