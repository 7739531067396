<template>
  <div>
    <StandardDialog
      v-model="localOpenDialog"
      persistent
      :title="title"
      dialog-height="calc(95vh-1)"
      dialog-width="95vw"
      pre-icon-title="mdi-package-up"
      :pre-icon-title-color="statusColorIconTitle"
    >
      <template v-slot:header-actions>
        <v-btn
          text
          class="ml-1 border-default"
          v-if="!!supplier && statusOrder === 'created'"
          @click="openDialogAddItems"
        >
          Aggiungi prodotto <v-icon color="blu">mdi-plus</v-icon>
        </v-btn>
        <v-btn
          v-else-if="!supplier && statusOrder === 'created'"
          text
          class="ml-1 border-default"
          @click="openGenericElementDialog"
        >
          Aggiungi prodotto <v-icon color="blu">mdi-plus</v-icon>
        </v-btn>
        <v-btn
          text
          class="ml-1 border-default"
          v-if="
            (statusOrder === 'waiting' || statusOrder === 'pending') &&
            editBeforeFulfill === true &&
            !!supplier
          "
          @click="openDialogAddItems"
        >
          Prodotti In Omaggio <v-icon color="blu">mdi-plus</v-icon>
        </v-btn>
        <v-btn
          text
          class="ml-1 border-default"
          :disabled="editNotValid"
          v-if="statusOrder === 'created'"
          @click="saveChangeStatus('waiting')"
        >
          In Attesa <v-icon color="orange">mdi-package-up</v-icon>
        </v-btn>
        <v-btn
          text
          class="ml-1 border-default"
          v-if="statusOrder === 'waiting' && !editBeforeFulfill"
          @click="editQtyToFulfill"
          :loading="loadingEvasion"
        >
          Evadi <v-icon color="green">mdi-package-up</v-icon>
        </v-btn>
        <v-btn
          text
          class="ml-1 border-default"
          :disabled="editNotValid"
          v-if="
            (statusOrder === 'waiting' && !!editBeforeFulfill) ||
            statusOrder === 'pending'
          "
          @click="checkQuantitiesDelivered"
          :loading="loadingEvasion"
        >
          Evadi <v-icon color="green">mdi-package-up</v-icon>
        </v-btn>
        <v-btn
          text
          class="ml-1 border-default"
          :disabled="editNotValid"
          @click="showConfirmRejectedDialog = true"
          v-if="
            !!supplier &&
            (statusOrder === 'pending' || statusOrder === 'waiting')
          "
        >
          Chiudi <v-icon color="red">mdi-package-up</v-icon>
        </v-btn>
        <v-btn
          text
          class="ml-1 border-default"
          @click="printOrder"
          v-if="!!supplier"
        >
          Stampa Ordine <v-icon>mdi-printer</v-icon>
        </v-btn>
      </template>
      <v-form class="mb-10" v-model="formValid">
        <v-subheader class="font-italic font-weight-light py-1"
          >Dati Generali</v-subheader
        >
        <v-row no-gutters>
          <v-col cols="3">
            <v-text-field
              v-model="codeOrder"
              filled
              disabled
              label="Codice Ordine"
              prepend-icon="mdi-code-tags"
              hide-details="auto"
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="descOrder"
              :disabled="statusOrder !== 'created'"
              :placeholder="descPlaceHolder"
              filled
              :rules="[presenceRule]"
              label="Descrizione Ordine"
              prepend-icon="mdi-clipboard-text"
              hide-details="auto"
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <DateTimePicker
              class="ml-2"
              :disabled="statusOrder !== 'created'"
              v-model="expectedDeliveryDate"
              dense
              filled
              label="Data di consegna prevista"
              :show-time="false"
              date-picker-width="300px"
              :min="(() => {
                let today = new Date()
                return new Date(today.setDate(today.getDate() + 1))
              })()"
            ></DateTimePicker>
          </v-col>
          <v-col cols="3">
            <DateTimeFormattor
              :with-time="
                statusOrder === 'fulfilled' || statusOrder === 'rejected'
                  ? true
                  : false
              "
              label="Date Evasione"
              :textFieldView="true"
              :dense="false"
              :notSpecifiedText="
                statusOrder === 'fulfilled' || statusOrder === 'rejected'
                  ? ''
                  : 'Ordine non evaso'
              "
              v-model="endDate"
              format="dd/mm/yyyy"
              rounded
            ></DateTimeFormattor>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="reason"
              :disabled="statusOrder !== 'created'"
              label="Note"
              filled
              hide-details="auto"
              prepend-icon="mdi-comment"
              rows="2"
              :rules="[max255CharRule]"
              rounded
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
      <div class="font-italic font-weight-light">Lista Prodotti Ordinati</div>

      <div
        class="mt-5 border-default"
        style="
          overflow-x: hidden;
        "
      >
        <OrderItemDataTable
          v-if="supplier"
          :show-select="false"
          item-key="id"
          fixed-header
          :loading="loading"
          :items="paginatedOrderItemList"
          :headers="headersOrderItem"
          :show-actions="true"
          :translator="translator"
          :page.sync="orderItemListPage"
          :row-per-page.sync="orderItemListRowPerPage"
          :total-pages.sync="orderItemListTotalPages"
        >
          <template v-slot:custom-code="{ item }">
            {{ item.code }}
          </template>
          <template v-slot:custom-subcodeList="{ item }">
            <v-btn fab small @click="openItemSubCodeDialog(item)" icon>
              <v-icon>mdi-qrcode-plus</v-icon>
            </v-btn>
          </template>
          <template v-slot:custom-pricePurchase="{ item }">
            <v-chip color="primary">
              € {{ item["pricePurchase"] ? item["pricePurchase"] : 0 }}</v-chip
            >
          </template>
          <template v-slot:custom-priceTot="{ item }">
            <v-chip color="primary">
              €
              {{
                item["pricePurchase"] && item["discount"] && item["qtyToOrder"]
                  ? Number(
                      Number(item["pricePurchase"]) *
                        (1 - Number(item["discount"]) / 100) *
                        item["qtyToOrder"]
                    ).toFixed(2)
                  : Number(item["pricePurchase"] * item["qtyToOrder"]).toFixed(
                      2
                    )
              }}</v-chip
            >
          </template>
          <template v-slot:custom-discountedPrice="{ item }">
            <v-chip color="primary">
              €
              {{
                item["pricePurchase"] && item["discount"]
                  ? Number(
                      Number(item["pricePurchase"]) *
                        (1 - Number(item["discount"]) / 100)
                    ).toFixed(2)
                  : Number(item["pricePurchase"]).toFixed(2)
              }}</v-chip
            >
          </template>
          <template v-slot:custom-qtyToOrder="{ item }">
            <v-text-field
              type="number"
              dense
              filled
              rounded
              :disabled="item.status != 'created'"
              :rules="[qtyCheck]"
              hide-details="auto"
              not-specified-text="0"
              v-model="item['qtyToOrder']"
            >
            </v-text-field>
          </template>
          <template v-slot:custom-qtyDelivered="{ item }">
            <v-text-field
              type="number"
              min="0"
              dense
              filled
              rounded
              :rules="[qtyCheck]"
              hide-details="auto"
              not-specified-text="0"
              disabled
              v-model="item['qtyDelivered']"
            >
            </v-text-field>
          </template>
          <template v-slot:custom-qtyToDeliver="{ item }">
            <v-text-field
              type="number"
              min="0"
              dense
              :rules="[
                qtyCheck,
                qtyOverflow(
                  item.qtyDelivered,
                  item.qtyToDeliver,
                  item.qtyToOrder
                ),
              ]"
              filled
              rounded
              hide-details="auto"
              not-specified-text="0"
              :disabled="item.status == 'fulfilled'"
              v-model="item['qtyToDeliver']"
            >
            </v-text-field>
          </template>
          <template v-slot:custom-discount="{ item }">
            <v-text-field
              type="number"
              min="0"
              dense
              :disabled="item.status != 'created'"
              rounded
              filled
              hide-details="auto"
              not-specified-text="0"
              v-model="item['discount']"
              @input="calcDiscount(item)"
            >
            </v-text-field>
          </template>
          <template v-slot:custom-qtyInStock="{ item }">
            <v-chip color="success"> {{ item.item.qtyInStock }}</v-chip>
          </template>
          <template v-slot:custom-status="{ item }">
            <v-tooltip allow-overflow bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-on="on"
                  v-bind="attrs"
                  :color="item.statusColor.color"
                >
                </v-chip>
              </template>
              <span> {{ item.statusColor.text }}</span>
            </v-tooltip>
          </template>
          <template v-slot:actions="{ item }">
            <div v-if="statusOrder === 'created'" style="min-width: 100px">
              <v-btn icon class="ml-1" @click="removeItem(item)">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </div>
            <div v-else></div>
          </template>
        </OrderItemDataTable>
        <GenericOrderItemDataTable
          v-else
          :show-select="false"
          item-key="id"
          fixed-header
          :loading="loading"
          :items="paginatedOrderItemList"
          :headers="headersGenericOrderItem"
          :show-actions="true"
          :translator="translator"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
        >
          <template v-slot:custom-pricePurchase="{ item }">
            <v-chip color="primary">
              € {{ item["pricePurchase"] ? item["pricePurchase"] : 0 }}</v-chip
            >
          </template>
          <template v-slot:custom-discountedPrice="{ item }">
            <v-chip color="primary">
              €
              {{
                item["pricePurchase"] && item["discount"]
                  ? Number(
                      Number(item["pricePurchase"]) *
                        (1 - Number(item["discount"]) / 100)
                    ).toFixed(2)
                  : Number(item["pricePurchase"]).toFixed(2)
              }}</v-chip
            >
          </template>
          <template v-slot:custom-discount="{ item }">
            <v-text-field
              :disabled="statusOrder == 'fulfilled'"
              class="border-default mt-3 mb-3"
              type="number"
              min="0"
              max="100"
              dense
              :rules="[]"
              @click.stop=""
              filled
              flat
              solo
              hide-details="auto"
              not-specified-text="0"
              v-model="item['discount']"
            >
            </v-text-field>
          </template>
          <template v-slot:custom-priceTot="{ item }">
            <v-chip color="primary">
              €
              {{
                item["pricePurchase"] && item["discount"] && item["qtyToOrder"]
                  ? Number(
                      Number(item["pricePurchase"]) *
                        (1 - Number(item["discount"]) / 100) *
                        item["qtyToOrder"]
                    ).toFixed(2)
                  : Number(item["pricePurchase"] * item["qtyToOrder"]).toFixed(
                      2
                    )
              }}</v-chip
            >
          </template>
          <template v-slot:custom-qtyToOrder="{ item }">
            <v-text-field
              :disabled="statusOrder == 'fulfilled'"
              class="border-default mt-3 mb-3"
              type="number"
              min="0"
              max="100"
              dense
              @click.stop=""
              :rules="[qtyCheck]"
              filled
              flat
              solo
              hide-details="auto"
              not-specified-text="0"
              v-model="item['qtyToOrder']"
            >
            </v-text-field> </template
          >
          <template v-slot:actions="{ item }">
            <v-btn
              :disabled="statusOrder == 'fulfilled'"
              icon
              @click="handleRemoveGenericItem(item)"
              ><v-icon> mdi-trash-can </v-icon></v-btn
            >
          </template>
        </GenericOrderItemDataTable>
      </div>

      <v-row>
        <v-spacer></v-spacer>
        <OrderSummary :selected-items="orderItemList"></OrderSummary>
      </v-row>

      <v-alert
        v-if="
          (!!editBeforeFulfill && statusOrder == 'waiting') ||
          statusOrder != 'created'
        "
        dense
      >
        <div class="font-italic font-weight-light">Lista Prodotti Omaggio</div>
      </v-alert>
      <GiftItemsDataTable
        v-if="
          (!!editBeforeFulfill && statusOrder == 'waiting') ||
          statusOrder != 'created'
        "
        :show-select="false"
        item-key="id"
        fixed-header
        :items="giftItemList"
        :headers="headersOrderGiftItem"
        :show-actions="true"
        :translator="translator"
      >
        <template v-slot:custom-qtyDelivered="{ item }">
          <v-text-field
            type="number"
            dense
            filled
            :disabled="item.status == 'fulfilled'"
            :rules="[qtyCheck]"
            hide-details="auto"
            not-specified-text="0"
            v-model="item['qtyDelivered']"
          >
          </v-text-field>
        </template>
        <template v-slot:custom-status="{ item }">
          <v-chip v-if="item.status == 'created'" color="yellow darken-2">
            Prodotti Aggiunti
          </v-chip>
          <v-chip v-else color="green darken-2">
            Prodotti Omaggio Evasi
          </v-chip>
        </template>
        <template v-slot:custom-qtyInStock="{ item }">
          <v-chip color="success"> {{ item.item.qtyInStock }}</v-chip>
        </template>
        <template v-slot:actions="{ item }">
          <v-btn
            v-if="item.status != 'fulfilled'"
            icon
            class="ml-1"
            @click="removeItem(item)"
          >
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
          <div v-else></div>
        </template>
      </GiftItemsDataTable>

      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn
          v-if="statusOrder != 'fulfilled' && statusOrder != 'rejected'"
          text
          class="border-default"
          color="error"
          @click="close"
          >Annulla</v-btn
        >
        <v-btn v-else text color="error" @click="close">Chiudi</v-btn>
        <v-btn
          text
          color="default"
          class="border-default"
          @click="save"
          :disabled="loading || editNotValid || isLicenseBlocked"
          v-if="
            !editBeforeFulfill &&
            statusOrder != 'fulfilled' &&
            statusOrder != 'rejected'
          "
          >Salva</v-btn
        >
        <v-btn
          v-if="editBeforeFulfill && variant !== 'invoice'"
          text
          :disabled="loading || editNotValid"
          @click="checkQuantitiesDelivered"
          class="border-default"
          :loading="loadingEvasion"
          >Evadi</v-btn
        >
      </template>
    </StandardDialog>
    <AddItemsToOrderDialog
      v-if="openDialogToAddItemsToOrder"
      v-model="openDialogToAddItemsToOrder"
      persistent
      title="Aggiungi prodotti ad ordine"
      dialog-height="calc(650-1)"
      dialog-width="75vw"
    >
      <template v-slot:header-actions>
        <div class="mr-2">
          <v-row>
            <v-text-field
              class="mt-3"
              v-model="barcodeInputKeyboard"
              dense
              solo
              flat
              prepend-icon="mdi-search-web"
              label="Cerca prodotto"
              hide-details="auto"
            ></v-text-field>
          </v-row>
        </div>
      </template>

      <div
        style="height: 100%; overflow-y: hidden"
        class="mt-5 border-default"
        ref="dataTableWrapper"
      >
        <ItemsDataTable
          v-model="itemsToAddSelected"
          :single-select="false"
          :height="dataTableHeight"
          :show-select="true"
          item-key="id"
          fixed-header
          class="mt-10"
          :items="filteredItemsToAddList"
          :headers="headersItem"
          :page.sync="pageItemToAdd"
          :row-per-page.sync="rowPerPageItemToAdd"
          :total-pages.sync="totalPagesItemToAdd"
          :show-actions="false"
          :translator="translator"
          :selectedItemOutsideTable="selectedItemOutsideTable"
          :loading="loadingItemToAdd"
          @input="handleSelection"
        >
          <template v-slot:custom-qtyInStock="{ item }">
            <v-chip v-if="item['qtyInStock'] > 0" color="success">
              {{ item["qtyInStock"] }}</v-chip
            >
            <v-chip v-else color="error">
              {{ item["qtyInStock"] ? item["qtyInStock"] : 0 }}</v-chip
            >
          </template>
          <template v-slot:custom-qtyTidying="{ item }">
            <v-text-field
              type="number"
              @click.stop=""
              dense
              filled
              :rules="[qtyCheck]"
              hide-details="auto"
              not-specified-text="0"
              v-model="item['qtyTidying']"
              class="border-default"
              solo
              flat
              :disabled="loadingItemToAdd"
              @input="checkAddItemsToOrder"
            >
            </v-text-field>
          </template>
        </ItemsDataTable>
      </div>

      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="error"
          class="border-default"
          @click="openDialogToAddItemsToOrder = false"
          >Annulla</v-btn
        >
        <v-btn
          text
          color="default"
          @click="addItemsToOrder"
          :disabled="loading || addItemValid || itemsToAddSelected.length == 0"
          class="border-default"
          >Aggiungi</v-btn
        >
      </template>
    </AddItemsToOrderDialog>
    <StandardDialog
      dialog-width="460"
      dialog-height="calc(360-10px)"
      persistent
      v-model="showErrorDialog"
    >
      <v-alert class="ma-2" dense outlined type="error">
        {{ errorMessage }}
      </v-alert>

      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn
          class="border-default"
          text
          color="error"
          @click="showErrorDialog = false"
        >Chiudi</v-btn>
      </template>
    </StandardDialog>
    <StandardDialog
      dialog-width="460"
      dialog-height="calc(360-10px)"
      persistent
      v-model="showPendingDialog"
    >
      <v-alert class="ma-2" dense outlined type="error">
        Alcuni prodotti hanno quantità minori di quelle ordinate, Evadere
        Parzialmente?
      </v-alert>

      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn
          class="border-default"
          text
          color="error"
          @click="showPendingDialog = false"
          >Annulla</v-btn
        >
        <v-btn
          text
          color="default"
          class="border-default"
          @click="saveChangeStatus('pending')"
          :loading="loadingEvasion"
          >Evadi Parziale</v-btn
        >
      </template>
    </StandardDialog>
    <ItemCreateDialog
      v-if="showItemCreateDialog"
      :openDialog="showItemCreateDialog"
      :barcodeScanned="barcodeInputKeyboard"
      :supplierSelected="supplier.id"
      rootFather="orderUpdateDialog"
      @close="showItemCreateDialog = false"
      @creation-item="handleItemAdded"
    ></ItemCreateDialog>
    <StandardDialog
      dialog-width="460"
      dialog-height="calc(360-10px)"
      persistent
      v-model="showConfirmRejectedDialog"
    >
      <v-alert class="ma-2" dense outlined type="error">
        Sicuro Di Chiudere L'Ordine?
      </v-alert>

      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn
          class="border-default"
          text
          color="error"
          @click="showConfirmRejectedDialog = false"
          >Annulla</v-btn
        >
        <v-btn
          class="border-default"
          text
          color="default"
          @click="saveChangeStatus('rejected')"
          >Chiudi Ordine</v-btn
        >
      </template>
    </StandardDialog>
    <ItemSubCodeDialog
      v-if="itemSubCodeDialog"
      :open-dialog="itemSubCodeDialog"
      :item-id="itemIdSelected"
      :order-id="Number(orderId)"
      @close="itemSubCodeDialog = false"
      @changed-code="handleChangeCode"
    >
    </ItemSubCodeDialog>

    <GenericElementListDialog
      :open-dialog="openGenericElementListDialog"
      @close="openGenericElementListDialog = false"
      @add-element="handleAddGenericItem"
    >
    </GenericElementListDialog>
  </div>
</template>

<script>
import GenericElementListDialog from "@/components/common/GenericElementListDialog.vue";

import OrderSummary from "@/components/areas/warehouse/orders/components/OrderSummary.vue";
import orderService from "@/services/warehouse/orders.service.js";
import itemService from "@/services/warehouse/items.service.js";
import invoiceService from "@/services/invoices/invoices.service.js";

import DateTimeFormattor from "@/components/common/DateTimeFormattor.vue";
import StandardMenu from "@/components/common/StandardMenu.vue";
import OrderItemDataTable from "@/components/common/TypeDataTable.vue";
import GenericOrderItemDataTable from "@/components/common/TypeDataTable.vue";

import ItemsDataTable from "@/components/common/TypeDataTable.vue";
import GiftItemsDataTable from "@/components/common/TypeDataTable.vue";
import StandardDialog from "@/components/common/StandardDialog";
import AddItemsToOrderDialog from "@/components/common/StandardDialog";
import itemVocabulary from "@/vocabulary/warehouse/item.vocabulary.js";
import invoiceVocabulary from "@/vocabulary/payments/invoice.vocabulary.js";
import movementService from "@/services/warehouse/movements.service.js";
import currentUser from "@/services/currentUser.service";
import ItemCreateDialog from "@/components/areas/warehouse/items/new/ItemCreateDialog.vue";
import headersDataTables from "./headersDataTables.json";
import ItemSubCodeDialog from "./ItemSubCodeDialog.vue";

import DateTimePicker from '@/components/common/DateTimePicker.vue'

export default {
  components: {
    OrderSummary,
    DateTimeFormattor,
    StandardDialog,
    OrderItemDataTable,
    AddItemsToOrderDialog,
    GenericOrderItemDataTable,
    ItemsDataTable,
    StandardMenu,
    ItemCreateDialog,
    GiftItemsDataTable,
    ItemSubCodeDialog,
    GenericElementListDialog,
    DateTimePicker
  },
  data: function () {
    return {
      itemSubCodeDialog: false,
      itemIdSelected: undefined,
      statusOrder: undefined,
      title: "",
      supplier: {},
      page: 1,
      rowPerPage: 20,
      totalPages: 2,
      pageItemToAdd: 1,
      rowPerPageItemToAdd: 200,
      loadingItemToAdd: false,
      totalPagesItemToAdd: 2,
      orderItemListPage: 1,
      orderItemListRowPerPage: 20,
      orderItemListTotalPages: 2,
      dataTableHeightValue: 400,
      openDialogToAddItemsToOrder: false,
      formValid: false,
      addItemValid: true,
      localOpenDialog: false,
      loading: false,
      localOrderId: 0,
      localOrder: [],
      orderItemList: [],
      paginatedOrderItemList: [],
      giftItemList: [],
      itemsToAddList: [],
      itemsToAddSelected: [],
      codeOrder: "",
      amount: undefined,
      codeOrder: undefined,
      reason: "",
      priceTotSelling: 0,
      priceTotPurchase: 0,
      priceTotNetPurchase: 0,
      codePlaceHolder: "ES...OR01",
      descOrder: "",
      descPlaceHolder: "ES...Prodotti Salone, Prodotti Bar",
      endDate: undefined,
      loadingEvasion: false,
      userId: undefined,
      editBeforeFulfill: false,
      barcodeInputKeyboard: undefined,
      selectedItemOutsideTable: undefined,
      showItemCreateDialog: false,
      showErrorDialog: false,
      showPendingDialog: false,
      showConfirmRejectedDialog: false,
      openGenericElementListDialog: false,
      expectedDeliveryDate: undefined,

      errorMessage: "",
      headersItem: headersDataTables.headersItem,
      headersOrderItem: headersDataTables.headersOrderItem,
      headersGenericOrderItem: headersDataTables.headersGenericOrderItem,
      headersOrderGiftItem: headersDataTables.headersOrderGiftItem,
      headersOrderItemBeforeFulfilling:
        headersDataTables.headersOrderItemBeforeFulfilling,
      headersOrderItemWaiting: headersDataTables.headersOrderItemWaiting,
      headersOrderItemRejected: headersDataTables.headersOrderItemRejected,

      qtyCheck: (qty) => {
        if (qty <= 0 && this.statusOrder == "created")
          return itemVocabulary.Correct_Qty.desc;
        else if (qty < 0) return itemVocabulary.Correct_Qty.desc;
        else return true;
      },
      qtyOverflow(qtyDelivered, qtyToDeliver, qtyToOrder) {
        if (Number(qtyDelivered) + Number(qtyToDeliver) <= Number(qtyToOrder))
          return true;
        else return "Quantità maggiore di quella ordinata";
      },
      presenceRule: (v) => {
        return !!v || "Il campo è obbligatorio";
      },
      max255CharRule: (v) => {
        if (v) {
          return v.length <= 255 || "Al massimo 5 caratteri";
        } else {
          return true;
        }
      },
      isLicenseBlocked: undefined
    };
  },
  computed: {
    statusColorIconTitle() {
      if (this.statusOrder == "created") return "E0E0E0";
      else if (this.statusOrder == "waiting") return "yellow darken-2";
      else if (this.statusOrder == "pending") return "orange darken-2";
      else if (this.statusOrder == "fulfilled") return "green darken-2";
      else if (this.statusOrder == "rejected") return "red darken-2";
    },
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
    editNotValid() {
      let check = false;
      let checkArray = [...this.orderItemList, ...this.giftItemList];
      check = checkArray.some((orderItem) => {
        return orderItem.qtyToOrder < 1;
      });
      if (!check) check = this.descOrder == "";

      if (!!this.editBeforeFulfill && !check) {
        check = checkArray.some((orderItem) => {
          return (
            (Number(orderItem.qtyDelivered) < 0 && !!orderItem.price == 0) || //caso giftItem
            Number(orderItem.qtyDelivered) + Number(orderItem.qtyToDeliver) >
              Number(orderItem.qtyToOrder) || //caso overflow listitem
            (Number(orderItem.qtyToDeliver) < 0 &&
              orderItem.status != "fulfilled") || // caso qty negative in aggiunta
            Number(orderItem.qtyToOrder) <= 0
          ); //caso in created
        });
      }
      return check;
    },
    filteredItemsToAddList() {
      return this.itemsToAddList.filter((i) => {
        return !this.barcodeInputKeyboard || i.barcodes.some((b) => b.code.includes(this.barcodeInputKeyboard)) || i.description.toLowerCase().includes(this.barcodeInputKeyboard.toLowerCase())
      })
    }
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: String,
      default: "0",
    },
    showRealQuantities: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "new",
      validator: function (value) {
        if (!["new", "edit", "detail, invoice"].includes(value)) {
          return "invalid variant";
        } else {
          return true;
        }
      },
    },
  },
  mounted() {
    if (this.showRealQuantities) this.editQtyToFulfill();
    this.localOpenDialog = this.openDialog;
    this.localOrderId = this.orderId;
    this.loadOrder(this.orderId).then(() => {
      if (!!this.supplier) this.loadOrderItems(this.orderId);
      else this.loadGenericOrderItems(this.orderId);
      this.setUser();
    });
  },
  methods: {
    handleChangeCode() {
      this.loadOrderItems(this.orderId);
    },
    setUser() {
      currentUser.getUser().then((user) => {
        this.userId = user.id;
      });
    },
    loadOrder(orderId) {
      return new Promise((resolve, reject) => {
        orderService.get(orderId).then((order) => {
          this.setLocalOrder(order);
          resolve();
        });
      });
    },
    setLocalOrder(order) {
      this.localOrder = order;
      this.localOrderId = order.id;
      this.supplier = order.supplier;
      this.codeOrder = order.code;
      this.descOrder = order.description;
      this.expectedDeliveryDate = new Date(order.expectedDeliveryDate)
      this.priceTotSelling = Number(order.priceTotSelling);
      this.priceTotPurchase = Number(order.priceTotPurchase);
      this.priceTotNetPurchase = Number(order.priceTotNetPurchase);
      this.statusOrder = order.status;
      this.endDate = order.endDate;
      this.discount = order.discount;
      this.orderItemList.extraDiscount = order.discount;
      this.orderItemList.statusOrder = order.status;
      this.amount = order.amount;
      this.reason = order.reason;
      this.title = !!this.supplier
        ? this.codeOrder + " per " + this.supplier.businessName
        : this.codeOrder + " ( ordine con prodotti non gestiti a magazzino )";
      if (this.statusOrder == "pending") this.editBeforeFulfill = true;
    },
    loadGenericOrderItems(orderId) {
      return new Promise((resolve, reject) => {
        orderService.getGenericOrderItems(orderId).then((genericOrderItems) => {
          let genericOrderItemListTemp = [];
          genericOrderItems.rows.map((genericOrderItem) => {
            genericOrderItem.description =
              genericOrderItem.genericItem.description;
            genericOrderItem.pricePurchase = Number(
              genericOrderItem.genericItem.price
            );
            genericOrderItem.discount = Number(
              !!genericOrderItem.discount ? genericOrderItem.discount : 0
            );
            genericOrderItem.qtyToOrder = Number(
              !!genericOrderItem.quantity ? genericOrderItem.quantity : 0
            );
            genericOrderItemListTemp.push(genericOrderItem);
          });
          this.orderItemList = genericOrderItemListTemp;
          this.orderItemList.extraDiscount = this.discount;
          this.orderItemList.statusOrder = this.statusOrder;
          this.calculatePaginatedOrderItemList()
        });
        resolve();
      });
    },
    loadOrderItems(orderId) {
      return new Promise((resolve, reject) => {
        let orderItemListTemp = [];
        orderService.getOrderItems(orderId, 1, 10000).then((orderItems) => {

          this.orderItemList = orderItems.rows;
          this.giftItemList = [];
          this.orderItemList.map((orderItem) => {
            orderItem.priceNetPurchase = orderItem.price;
            orderItem.pricePurchase = orderItem.grossPrice;
            if (orderItem.price > 0) {
              orderItem.qtyDelivered = Math.floor(orderItem.qtyDelivered);
              orderItem.supplier = orderItem.item.supplier;
              orderItem.internalCode = orderItem.item.internalCode;
              orderItem.code = orderItem.item.code;
              orderItem.itemType = orderItem.item.itemType;
              orderItem.description = orderItem.item.description;
              orderItem.qtyInStock = orderItem.item.qtyInStock;
              orderItem.priceSelling = orderItem.item.priceSelling;
              orderItem.statusColor = this.getColorStatus(orderItem.status);
              orderItem.priceNetPurchaseSum =
                Number(orderItem.priceNetPurchase) *
                Number(orderItem.qtyToOrder);
              orderItemListTemp.push(orderItem);
            } else {
              orderItem.qtyDelivered = Math.floor(orderItem.qtyDelivered);
              orderItem.supplier = orderItem.item.supplier;
              orderItem.internalCode = orderItem.item.internalCode;
              orderItem.code = orderItem.item.code;
              orderItem.itemType = orderItem.item.itemType;
              orderItem.description = orderItem.item.description;
              orderItem.qtyInStock = orderItem.item.qtyInStock;
              orderItem.priceSelling = orderItem.item.priceSelling;
              orderItem.statusColor = this.getColorStatus(orderItem.status);
              this.giftItemList.push(orderItem);
            }
          });
          this.orderItemList = orderItemListTemp;
          this.orderItemList.extraDiscount = this.discount;
          this.orderItemList.statusOrder = this.statusOrder;

          if (this.statusOrder == "fulfilled")
            this.headersOrderItem = this.headersOrderItemBeforeFulfilling;
          else if (
            (this.statusOrder == "waiting" || this.statusOrder == "pending") &&
            !!this.editBeforeFulfill
          )
            this.headersOrderItem = this.headersOrderItemBeforeFulfilling;
          else if (this.statusOrder == "waiting" && !this.editBeforeFulfill)
            this.headersOrderItem = this.headersOrderItemWaiting;
          else if (this.statusOrder == "rejected")
            this.headersOrderItem = this.headersOrderItemRejected;

          this.calculatePaginatedOrderItemList()
          
          resolve();
        });
      });
    },
    checkAddItemsToOrder() {
      this.addItemValid = this.itemsToAddSelected.some((itemToAdd) => {
        return itemToAdd.qtyTidying < 1;
      });
    },
    updateField(nameField, value) {
      this.$store.dispatch("warehouse/orders/updateField", {
        field: nameField,
        value: value,
      });
    },
    openGenericElementDialog() {
      this.openGenericElementListDialog = true;
    },
    handleRemoveGenericItem(genericItem) {
      let _genericItem = {
        id: genericItem.genericItemId,
      };

      if (!genericItem.id) {
        const index = this.orderItemList.findIndex(function (e) {
          return e.id == genericItem.id;
        });
        if (index != -1) this.orderItemList.splice(index, 1);
      } else {
        orderService
          .removeGenericItem(this.localOrderId, _genericItem)
          .then(async (orderGenericItemDeleted) => {
            const index = this.orderItemList.findIndex(function (e) {
              return e.id == orderGenericItemDeleted[0].id;
            });
            if (index != -1) this.orderItemList.splice(index, 1);
          });
      }
    },
    handleAddGenericItem(genericItem) {
      orderService
        .addGenericItems(this.localOrderId, [genericItem])
        .then((value) => {
          this.loadGenericOrderItems(this.orderId);
        });
    },
    saveOrder(closeDialog = true) {
      let currentOrderEdit = this.$store.state.warehouse.orders.data.order;
      let orderItemsToUpload = this.orderItemList.map((orderItem) => {
        return {
          id: orderItem.id,
          orderId: orderItem.orderId,
          itemId: orderItem.itemId,
          qtyToOrder: Number(orderItem.qtyToOrder),
          qtyDelivered: orderItem.qtyDelivered,
          status: orderItem.status,
          price: orderItem.priceNetPurchase,
          grossPrice: orderItem.pricePurchase,
          discount: orderItem.discount,
        };
      });

      orderService
        .updateOrderItems(this.localOrderId, orderItemsToUpload)
        .then(() => {
          orderService
            .updateOrder(currentOrderEdit)
            .then((value) => {
              this.$emit("reload-orders");
              if(closeDialog) this.$emit('close')
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log("error");
        });
    },
    saveGenericOrder() {
      let currentOrderEdit = this.$store.state.warehouse.orders.data.order;
      let genericOrderItemsToUpload = this.orderItemList.map(
        (genericOrderItem) => {
          return {
            id: genericOrderItem.id,
            orderId: genericOrderItem.orderId,
            genericItemId: genericOrderItem.genericItemId,
            quantity: Number(genericOrderItem.qtyToOrder),
            status: genericOrderItem.status,
            price: genericOrderItem.price,
            discount: genericOrderItem.discount,
          };
        }
      );

      orderService
        .updateGenericOrderItems(this.localOrderId, genericOrderItemsToUpload)
        .then(() => {
          orderService
            .updateOrder(currentOrderEdit)
            .then((value) => {
              this.$emit("reload-orders");
            })
            .catch((err) => {});
        })
        .catch((err) => {});
    },
    save() {
      if (!!this.supplier) this.saveOrder();
      else this.saveGenericOrder();
    },

    close() {
      this.$emit("close");
    },
    removeItem(item) {
      let _item = {
        id: item.itemId,
      };
      orderService
        .removeItem(this.localOrderId, _item)
        .then(async (orderItemDeleted) => {
          if (this.statusOrder != "created") {
            const index = this.giftItemList.findIndex(function (e) {
              return e.id == orderItemDeleted[0].id;
            });
            if (index != -1) this.giftItemList.splice(index, 1);
          } else if (this.statusOrder == "created") {
            const index = this.orderItemList.findIndex(function (e) {
              return e.id == orderItemDeleted[0].id;
            });
            if (index != -1) this.orderItemList.splice(index, 1);
          }

          this.saveOrder(false)
        });
    },
    openDialogAddItems() {
      this.loadAllItemsAvailable()
        .then(() => {
          this.openDialogToAddItemsToOrder = true;
        })
        .catch((err) => console.log(err));
    },
    async addItemsToOrder() {
      if (this.statusOrder == "created") {
        for(let i = 0; i < this.itemsToAddSelected.length; i += 1) {
          let item = this.itemsToAddSelected[i]
          
          try {
            let orderItem = await orderService.addItem(
              this.localOrderId,
              item.id,
              item.qtyTidying,
              item.pricePurchase,
              item.priceNetPurchase,
              item.discount,
              item.code
            )

            orderItem.priceNetPurchase = orderItem.price;
            orderItem.qtyDelivered = Math.floor(orderItem.qtyDelivered);
            orderItem.supplier = orderItem.item.supplier;
            orderItem.internalCode = orderItem.item.internalCode;
            orderItem.code = orderItem.item.code;
            orderItem.itemType = orderItem.item.itemType;
            orderItem.description = orderItem.item.description;
            orderItem.qtyInStock = orderItem.item.qtyInStock;
            orderItem.pricePurchase = orderItem.grossPrice;
            orderItem.priceSelling = orderItem.item.priceSelling;
            orderItem.statusColor = this.getColorStatus(orderItem.status);
            orderItem.qtyToDeliver =
              orderItem.qtyToOrder - orderItem.qtyDelivered;
            orderItem.priceNetPurchaseSum =
              Number(orderItem.priceNetPurchase) *
              Number(orderItem.qtyToOrder);
            this.orderItemList.push(orderItem);
          } catch(err) {
            this.errorMessage = err;
            this.showErrorDialog = true;
          }
        }
      } else if (this.statusOrder == "waiting" || this.statusOrder == "pending") {
        for(let i = 0; i < this.itemsToAddSelected.length; i += 1) {
          let item = this.itemsToAddSelected[i]

          try {
            let orderItem = await orderService.addItem(
              this.localOrderId,
              item.id,
              item.qtyTidying,
              0,
              0,
              0,
              item.code
            )

            orderItem.priceNetPurchase = orderItem.price;
            orderItem.qtyDelivered = Math.floor(orderItem.qtyToOrder);
            orderItem.supplier = orderItem.item.supplier;
            orderItem.internalCode = orderItem.item.internalCode;
            orderItem.code = orderItem.item.code;
            orderItem.itemType = orderItem.item.itemType;
            orderItem.description = orderItem.item.description;
            orderItem.qtyInStock = orderItem.item.qtyInStock;
            orderItem.pricePurchase = orderItem.grossPrice;
            orderItem.priceSelling = orderItem.item.priceSelling;
            orderItem.statusColor = this.getColorStatus(orderItem.status);
            this.giftItemList.push(orderItem);
          } catch(error) {
            this.errorMessage = err;
            this.showErrorDialog = true;
          }
        }
      }

      this.saveOrder(false)
      this.openDialogToAddItemsToOrder = false;
      this.itemsToAddSelected = [];
    },
    editQtyToFulfill() {
      this.headersOrderItem = this.headersOrderItemBeforeFulfilling;
      this.editBeforeFulfill = true;
    },
    loadAllItemsAvailable() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        this.loadingItemToAdd = true;
        let checkArray = [...this.orderItemList, ...this.giftItemList];
        let itemsIdsAlreadyPresent = [];
        checkArray.map((itemAlreadyPresent) => {
          if (itemAlreadyPresent.status != "fulfilled")
            itemsIdsAlreadyPresent.push(itemAlreadyPresent.itemId);
        });
        itemService
          .list(this.pageItemToAdd, this.rowPerPageItemToAdd, [
            {
              field: "supplier",
              operator: "custom",
              value: this.supplier.id,
            },
            {
              field: "itemsIdsAlreadyPresent",
              operator: "custom",
              value: itemsIdsAlreadyPresent,
            }
          ])
          .then((results) => {
            if (this.rowPerPageItemToAdd != results.rowPerPage) {
              this.rowPerPageItemToAdd = results.rowPerPage;
            }

            if (this.pageItemToAdd != results.page) {
              this.pageItemToAdd = results.page;
            }

            this.totalPagesItemToAdd = results.totalPages;
            if (this.totalPagesItemToAdd < this.pageItemToAdd && this.totalPagesItemToAdd > 0) {
              this.pageItemToAdd = this.totalPagesItemToAdd;
            }

            this.itemsToAddList = results.rows;
            this.loading = false;
            this.loadingItemToAdd = false;
            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    translator(field, value) {
      if (field == "itemType" && value) {
        return value.name;
      } else if (field == "supplier" && value) {
        return value.businessName;
      }
    },
    handleSelection() {
      this.checkAddItemsToOrder();
    },
    printOrder() {
      orderService
        .print(this.localOrder)
        .then((result) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    saveChangeStatus(nextStep) {
      this.loadingEvasion = true;
      let endDate;
      if (nextStep == "fulfilled" || nextStep == "rejected")
        endDate = new Date();
      orderService
        .updateOrder({
          id: this.localOrderId,
          status: nextStep,
          endDate: endDate,
        })
        .then(async (value) => {
          if (nextStep == "fulfilled") {
            if (!!this.supplier) {
              let completeArray = [...this.orderItemList, ...this.giftItemList];
              let orderItemsToUpload = completeArray.map((orderItem) => {
                return {
                  id: orderItem.id,
                  orderId: orderItem.orderId,
                  itemId: orderItem.itemId,
                  qtyToOrder: Number(orderItem.qtyToOrder),
                  qtyDelivered:
                    orderItem.price != 0
                      ? Number(orderItem.qtyDelivered + orderItem.qtyToDeliver)
                      : Number(orderItem.qtyDelivered),
                  price: Number(orderItem.price),
                  status: "fulfilled",
                };
              });

              await orderService.updateOrderItems(
                this.localOrderId,
                orderItemsToUpload
              );

              this.addMovementItems()
                .then(async () => {
                  this.loadingEvasion = false
                  this.$emit("close", nextStep);

                  //KEEP COMMENT
                  // await this.createInvoice(orderItemsToUpload);
                })
                .catch((err) => {});
            } else {
              let genericOrderItemsToUpload = this.orderItemList.map(
                (genericOrderItem) => {
                  return {
                    id: genericOrderItem.id,
                    orderId: genericOrderItem.orderId,
                    genericItemId: genericOrderItem.genericItemId,
                    quantity: Number(genericOrderItem.qtyToOrder),
                    discount: Number(genericOrderItem.discount),
                    price: Number(genericOrderItem.price),
                    status: "fulfilled",
                  };
                }
              );

              await orderService.updateGenericOrderItems(
                this.localOrderId,
                genericOrderItemsToUpload
              );
              await this.$emit("close", "fulfilled");
            }
          } else if (nextStep == "waiting") {
            let orderItemsToUpload = this.orderItemList.map((orderItem) => {
              return {
                id: orderItem.id,
                orderId: orderItem.orderId,
                itemId: orderItem.itemId,
                qtyToOrder: Number(orderItem.qtyToOrder),
                qtyDelivered: orderItem.qtyDelivered,
                status: "waiting",
                price: orderItem.priceNetPurchase,
                grossPrice: orderItem.pricePurchase,
                discount: orderItem.discount,
              };
            });
            orderService
              .updateOrderItems(this.localOrderId, orderItemsToUpload)
              .then(() => {
                this.$emit("close", nextStep);
              })
              .catch((err) => console.log(err));
          } else if (nextStep == "pending") {
            let completeArray = [...this.orderItemList, ...this.giftItemList];
            let orderItemsToUpload = completeArray.map((orderItem) => {
              return {
                id: orderItem.id,
                orderId: orderItem.orderId,
                itemId: orderItem.itemId,
                qtyToOrder: Number(orderItem.qtyToOrder),
                qtyDelivered:
                  orderItem.price != 0
                    ? Number(orderItem.qtyDelivered) +
                      Number(orderItem.qtyToDeliver)
                    : Number(orderItem.qtyDelivered),
                status:
                  orderItem.qtyDelivered + orderItem.qtyToDeliver ==
                    orderItem.qtyToOrder || orderItem.price == 0
                    ? "fulfilled"
                    : "pending",
                endDate: new Date(),
              };
            });
            orderService
              .updateOrderItems(this.localOrderId, orderItemsToUpload)
              .then(async () => {
                await this.addMovementItems();
                this.showPendingDialog = false;
                this.$emit("close", nextStep);
              })
              .catch((err) => console.log(err));
          } else if (nextStep == "rejected") {
            this.showConfirmRejectedDialog = false;
            let orderItemsToUpload = this.orderItemList.map((orderItem) => {
              return {
                id: orderItem.id,
                orderId: orderItem.orderId,
                itemId: orderItem.itemId,
                qtyToOrder: Number(orderItem.qtyToOrder),
                qtyDelivered: Number(orderItem.qtyDelivered),
                status:
                  orderItem.qtyDelivered == orderItem.qtyToOrder
                    ? "fulfilled"
                    : "rejected",
              };
            });
            orderService
              .updateOrderItems(this.localOrderId, orderItemsToUpload)
              .then(async () => {
                //KEEP COMMENT
                //await this.createInvoice(orderItemsToUpload);

                this.showPendingDialog = false;
                this.$emit("close", nextStep);
              })
              .catch((err) => console.log(err));
          } else this.$emit("close", nextStep);
        });
    },
    createInvoice(orderItemsToUpload) {
      new Promise(async (resolve, reject) => {
        try {
          let items = [];
          orderItemsToUpload.map((orderItem) => {
            if (orderItem.qtyDelivered > 0)
              items.push({
                id: orderItem.itemId,
                quantity: orderItem.qtyDelivered,
                price: orderItem.price,
              });
          });

          let supplier = {
            id: this.supplier.id,
          };
          let invoice = {
            amount: this.priceTotNetPurchase,
            description:
              "Fattura  automatica ordine " +
              this.codeOrder +
              " -" +
              this.descOrder,
          };

          let order = {
            id: this.localOrderId,
          };

          invoiceService
            .createFromOrder(invoice, supplier, order, items)
            .then((value) => {
              this.$emit("close", "fulfilled_with_invoice");
            })
            .catch((err) => {
              if (
                err.message === invoiceVocabulary.DataInvoice_NotPresent["key"]
              ) {
                this.$emit("close", "fulfilled");
                this.$delegates.snackbar(
                  invoiceVocabulary.DataInvoice_NotPresent["desc"]
                );
              }
            });
          resolve(invoiceCreated);
        } catch (err) {
          reject("invoice not created");
        }
      });
    },
    handleItemAdded(newItem) {
      this.loadOrderItems(this.localOrderId).then(async () => {
        await this.loadAllItemsAvailable();
        this.showItemCreateDialog = false;
      });
    },
    openItemCreateDialog() {
      this.showErrorDialog = false;
      this.showItemCreateDialog = true;
    },
    checkQuantitiesDelivered() {
      let check = this.orderItemList.some((orderItem) => {
        return (
          orderItem.qtyDelivered + orderItem.qtyToDeliver < orderItem.qtyToOrder
        );
      });
      !!check
        ? (this.showPendingDialog = true)
        : this.saveChangeStatus("fulfilled");
    },
    getColorStatus(status) {
      switch (status) {
        case "created":
          return {
            color: "E0E0E0",
            text: "Prodotto Creato",
          };
        case "waiting":
          return {
            color: "yellow darken-2",
            text: "Prodotto In Attesa",
          };
        case "pending":
          return {
            color: "orange darken-2",
            text: "Prodotto Parzialmente Evaso",
          };
        case "fulfilled":
          return {
            color: "green darken-2",
            text: "Prodotto Evaso",
          };
        case "rejected":
          return {
            color: "red darken-2",
            text: "Prodotto Non Arrivato",
          };
      }
    },
    addMovementItems() {
      return new Promise((resolve, reject) => {
        let itemsToUpload = [];
        this.orderItemList.map((orderItem) => {
          if (orderItem.status != "fulfilled" && orderItem.qtyToDeliver > 0) {
            itemsToUpload.push({
              id: orderItem.itemId,
              qtyDelivered: orderItem.qtyToDeliver,
              code: orderItem.code,
              price: orderItem.price,
            });
          }
        });
        this.giftItemList.map((orderItem) => {
          if (orderItem.status != "fulfilled" && orderItem.qtyDelivered > 0) {
            itemsToUpload.push({
              id: orderItem.itemId,
              qtyDelivered: orderItem.qtyDelivered,
              code: orderItem.code,
              price: 0,
            });
          }
        });
        movementService
          .uploadFromOrder(itemsToUpload, this.userId, this.localOrderId)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    calcDiscount(item) {
      if (item.discount >= 0) {
        item.priceNetPurchase = item.pricePurchase * (1 - item.discount / 100);
      } else item.discount = 0;
    },
    openItemSubCodeDialog(orderItem) {
      if (!!orderItem && !!orderItem.itemId) {
        this.itemIdSelected = orderItem.itemId;
        this.itemSubCodeDialog = true;
      }
    },
    calculatePaginatedOrderItemList() {
      if (!this.orderItemList || this.orderItemList.length == 0) {
        this.paginatedOrderItemList = [];
      }

      this.orderItemListTotalPages = Math.ceil(this.orderItemList.length / this.orderItemListRowPerPage);

      let offset = (this.orderItemListPage - 1) * this.orderItemListRowPerPage;
      this.paginatedOrderItemList = this.orderItemList.slice(offset, (this.orderItemListPage * this.orderItemListRowPerPage));
    }
  },
  watch: {
    localOrderId(newVal) {
      this.updateField("id", newVal);
    },
    formValid(newVal) {
      this.$emit("update:valid", newVal);
      if (this.bus) {
        this.bus.$emit("update:valid", newVal);
      }
      this.$store.dispatch("warehouse/orders/setValidState", newVal);
    },
    descOrder(newVal) {
      this.updateField("description", newVal);
    },
    reason(newVal) {
      this.updateField("reason", newVal);
    },
    expectedDeliveryDate(newVal) {
      this.updateField("expectedDeliveryDate", newVal)
    },
    itemsToAddSelected(newVal) {
      this.itemsToAddSelected = newVal;
    },
    openDialogToAddItemsToOrder(newVal) {
      if (!newVal) this.barcodeInputKeyboard = "";
    },
    orderItemList: {
      deep: true,
      handler() {
        this.calculatePaginatedOrderItemList()
      }
    },
    orderItemListPage() {
      this.calculatePaginatedOrderItemList()
    },
    orderItemListRowPerPage() {
      this.calculatePaginatedOrderItemList()
    },
    pageItemToAdd() {
      this.loadAllItemsAvailable();
    },
    rowPerPageItemToAdd() {
      this.loadAllItemsAvailable();
    },
  },
  barcode: {
    scan(barcode) {
      this.barcodeInputKeyboard = barcode;
      this.searchBarcodeInputKeyboard();
    },
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
};
</script>

<style>
</style>